import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import ListPage from './pages/list-page.js';
import SinglePage from './pages/details-page.js';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HumanVerification from './HumanVerification';
import UpdateScreen from './pages/components/update/updateScreen';
import OptionsScreen from './pages/components/options/optionsScreen';

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
	},
	{
		path: '/listings',
		element: <ListPage />,
	},
	{
		path: '/details/:id',
		element: <SinglePage />,
	},
	{
		path: '/human-verification', // Add this route
		element: <HumanVerification />,
	},
	{
		path: '/update', // Add this route for the update screen
		element: <UpdateScreen />,
	  },
	{
		path: '/options', // Add this route for the update screen
		element: <OptionsScreen />,
	  },
]);
ReactDOM.createRoot(document.getElementById('root')).render(
	<RouterProvider router={router} />
);
